
import React from "react";

import HighlightPhone from './common/HighlightPhone';

function TextBox(props) {
  return <div className="border-2 rounded-xl border-white px-3 pt-2 pb-4 text-justify sm:w-1/2 m-4">
    <img className="block w-16 mx-auto" alt="icon" src={props.icon}></img>
    {props.children}
  </div>
}

function ProjectBoss() {
  const color = "#7e7d7f";

  function genSystemScreenshots() {
    return ['FCL/LCL', 'AIR FREIGHT & COURIER', 'TRUCKING'].map((name, i) =>
      <div key={i} className="w-52 mx-6 my-4">
        <img className="block w-full" alt="boss screenshot" src={`/images/project-boss-screenshot-${i}.png`}></img>
      </div>);
  }

  return (
    <div className="app-max-width">
      <div className="mt-10">

        <img className="w-full block" src="/images/project-boss.png" alt="project boss"></img>
        <div className="text-white text-center pt-6 px-10 pb-10" style={{backgroundColor: color}}>
          <div className="max-w-screen-md	mx-auto">
            <div className="mb-10">
              <h2 className="text-3xl text-bold my-3">Customized Bid Optimization Strategic Sourcing for Transportation</h2>
              <p>BOSS is a customized optimization and AI-based decision support system that helps large-scale shippers make their transportation procurement and analyses processes significantly more efficient and dramatically less costly. BOSS is an award-winning decision support system system that caters for all transportation modes, including sea freight, air freight, courier services, and trucking.</p>
            </div>

            <div className="flex flex-wrap justify-center">
              {genSystemScreenshots()}
            </div>

            <div className="flex flex-wrap sm:flex-nowrap my-4 justify-center">
              <TextBox icon="/images/icon-cup.png">
                <p className="mb-3">Won 2nd place in Philips’ Innovation Contest in 2006</p>
                <p className="mb-3">Air Courier/Air Freight System won the AAAI Innovation Applications of AI Award in 2006</p>
                <p className="mb-3">2009 Franz Edelman Award Semi-Finalist</p>
              </TextBox>

              <TextBox icon="/images/icon-money.png">
                <p className="mb-3">FCL system alone has been validated to have saved our client USD20 million/year in 2006 and 2007</p>
                <p className="mb-3">For other transportation modes, our client achieved about 10% savings of their procurement volume</p>
                <p className="mb-3">In 2008, complete BOSS system saved our client about USD100 million (Validated)</p>
              </TextBox>

            </div>
            <HighlightPhone color1="#fff" color2={color} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectBoss;
