import BannerVideo from './BannerVideo';
import CompanyVision from './CompanyVision';
import OurBusiness from './OurBusiness';
import SampleClients from './SampleClients';
import ProjectVRP from './ProjectVRP';
import ProjectBoss from './ProjectBoss';
import ProjectML from './ProjectML';
import SpinOffs from './SpinOffs';
import Research from './Research';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';


function Home() {
  return (
    <>
      <BannerVideo />
      <CompanyVision />
      <OurBusiness />
      <SampleClients />
      <ProjectVRP />
      <ProjectBoss />
      <ProjectML />
      <SpinOffs />
      <AboutUs />
      <ContactUs />
      <Research />
    </>
  );
}

export default Home;
