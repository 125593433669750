
import React from "react";

import HighlightPhone from './common/HighlightPhone';

function SpinOffCompany(props) {
  return (
    <div className="flex flex-col sm:flex-row mb-4">
      <div className="flex-none w-48 mx-auto sm:mr-6">
        <img className="w-full p-4" src={props.src} alt="logo"></img>
      </div>
      <div className="flex-grow text-justify border-2 border-gray-300 rounded-lg p-4">
        {props.children}
      </div>
    </div>
  );
}

function SpinOffs() {
  return (
    <div className="app-max-width">
      <div className="mt-10">
        <a id="spin-offs">
          <img className="w-full block" src="/images/spin-offs.png" alt="spin-offs"></img>
        </a>
        <div className="text-gray-600 text-center pt-6 px-10 pb-2">

          <div className="max-w-screen-md mx-auto">
            <SpinOffCompany src="/images/spin-off-ascended.png">
              <p className="mb-2">AscendED is a provider of instructional products and related services for higher and professional education advancement. Our mission is to create borderless opportunities to empower minds.</p>
              <p>Our range of products and services include instructional technology (Online Judge), Competency Testing, Specialist Recruitment, Professional Workshops and Seminars, and Institutional Ranking (Bibliometrics).</p>
            </SpinOffCompany>

            <SpinOffCompany src="/images/spin-off-wifreight.png">
              <p className="mb-2">WiFreight is a provider of customized optimization and AI-based decision support systems to help small and medium-sized logistics service providers and large-scale shippers procure transportation services.</p>
              <p>For small- and medium-sized logistics service providers, we have the Rate Management Tool (RMT) and Freight Quotes Procurement (FQP). For large-scale shippers, we have BOSS, which caters for all transportation modes, including sea freight, air freight, courier services, and trucking.</p>
            </SpinOffCompany>

            <SpinOffCompany src="/images/spin-off-cyclone.png">
              <p className="mb-2">Cyclone Ventures is a provider of customized optimization and AI-based systems with underlying digital twins.</p>
              <p className="mb-2">Some of the services we provide under Cyclone Ventures include indoor and outdoor wayfinding, precision asset tracking, virtual entities and cities, personal re-identification, personal mobility analytics, transportation simulation and vehicle routing.</p>
            </SpinOffCompany>

            <HighlightPhone color1="#801b20" color2='white' />
          </div>

        </div>
      </div>
    </div>
  );
}

export default SpinOffs;
