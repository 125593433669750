import React from "react";

function Footer() {
  const currentYear = (new Date()).getFullYear();
  return (
    <div>
      <footer className="bg-gray-200 text-gray-800 px-5 py-3 text-sm">
        &copy; {currentYear} RedJasper. All rights reserved.
      </footer>
    </div>
  );
}

export default Footer;
