
import React from "react";

import HorizontalBar from './common/HorizontalBar';

function CompanyVision(props) {
  const buttons = [
    {text: 'CONSULTING', href: '#consulting'},
    {text: 'PROJECTS', href: '#projects'},
    {text: 'SPIN-OFFS', href: '#spin-offs'},
  ];
  return (
    <div className="app-max-width">
      <div className="relative bg-center bg-cover bg-no-repeat h-96" style={{
        backgroundImage: 'url(/images/company-vision-bg.png)'
      }}>
        <div className="absolute w-full top-0">
          <HorizontalBar color="white" />
        </div>
        <div className="pt-24 text-white">
          <h2 className="text-3xl sm:text-4xl md:text-5xl mb-8">Tomorrow's Technologies Today</h2>
          <p className="px-5 max-w-screen-md mx-auto">
            We are a boutique technology consultancy and incubator of deep-tech products and services. We harness our advanced research capabilities to create innovative products, applications and solutions that address current and emerging needs of our clients.
          </p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row my-8 justify-center">
        {buttons.map((btn, i) =>
        <a key={i} href={btn.href} className="border-2 rounded-lg text-gray-400 px-4 py-2 mx-4 my-2">
          {btn.text}
        </a>)}
      </div>
    </div>
  );
}

export default CompanyVision;
