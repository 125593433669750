import { React, useEffect, useState } from "react";

function BannerVideo() {
  const [videoWidth, setVideoWidth] = useState(null);

  useEffect(() => {
    const headerH = 88;
    const videoW = 1920;
    const videoH = 1080;
    let windowW = window.innerWidth;
    let windowH = window.innerHeight - headerH;
    let width = windowW;
    let height = windowW * (videoH / videoW);
    if (height > windowH) {
      height = windowH;
      width = height * (videoW / videoH);
    }
    setVideoWidth(width);
  }, []);

  return (
    <div className="bg-black bg-center bg-cover bg-no-repeat">
      <div className="w-full mx-auto"  style={{width: videoWidth}}>
      <video className="block w-full focus:outline-none"
        src="https://www.redjasper.com/public/redjasper-videos/redjasper-v6.mp4" controls autoPlay muted playsInline loop></video>
      </div>
    </div>
  );
}

export default BannerVideo;
