
import React from "react";

function HighlightPhone(props) {

  return (
    <div className="my-6 mx-auto w-60">
      <a className="block w-full py-2 px-6 text-2xl" style={{
        backgroundColor: props.color1,
        color: props.color2,
        }} href="/#contact-us-form">Become a Client</a>
      <div className="text-2xl" style={{
        color: props.color1,
        }}></div>
    </div>
  );
}

export default HighlightPhone;
