
import React from "react";

import HighlightPhone from './common/HighlightPhone';

function OurBusiness(props) {
  const borderClassName = "w-full mx-4 sm:max-w-md border-2 rounded-lg p-4 mb-3 text-gray-600";
  const titleClassName = "text-md font-bold mb-2";
  const paragraphClassName = "text-justify mb-2";

  return (
    <div className="app-max-width">
      <a id="consulting">
        <div className="w-full mb-10 h-60 md:h-80 bg-cover bg-center bg-no-repeat" style={{
          backgroundImage: 'url(/images/bg-clock.png)',
        }}></div>
      </a>
      <div className="flex flex-wrap justify-center">

        <div className={borderClassName}>
          <h3 className={titleClassName}>Business Value Modeling (BVM)</h3>
          <p className={paragraphClassName}>BVM involves structuring an organization based on different major activities such as demand generation, supply fulfillment, and support activities. These major activities are subdivided into key functions, and then further organized by their work processes.
          </p>
          <p className={paragraphClassName}>For each of these constituents, we first perform an initial but detailed assessment, derive performance metrics, and then measure the improvements in order to derive the objective ROIs for the client.
          </p>
          <p className={paragraphClassName}>Based on the client organization’s detailed assessments, we develop a set of performance indicators; some of which are selected as Key Performance Indicators (KPIs). These KPIs are leading indicators of the health of a company and linked to the financial indicators which report the performance of the company for a certain past period. </p>
        </div>

        <div className={borderClassName}>
          <h3 className={titleClassName}>Digital Transformation</h3>
          <p className={paragraphClassName}>We are a full-service Digital Transformation Consultancy (DTC), helping our clients formulate and implement their digital transformation strategy.
          </p>
          <p className={paragraphClassName}>Typically, we would first perform in-depth SWOT, descriptive and diagnostic analysis of the client’s current tech infrastructure, processes, people and organization.
          </p>
          <p className={paragraphClassName}>Based on the in-depth analysis, we would formulate and implement a customized digital transformation strategy, solution and/or system with the view of achieving long-term and short-term goals for our clients.
          </p>
          <p className={paragraphClassName}>In DTC, we conduct leading edge analytics leveraging technologies such as machine learning, optimization, and simulation in predictive and prescriptive analytics.
          </p>
        </div>
      </div>
      <HighlightPhone color1="#821a1c" color2="white" />
    </div>
  );
}

export default OurBusiness;
