
import React from "react";

import HorizontalBar from './common/HorizontalBar';
import HighlightPhone from './common/HighlightPhone';

function ProjectVRP() {
  const color = "#7e7d7f";
  return (
    <div className="app-max-width">
      <HorizontalBar color="#a7a7a7">
        <div className="pt-1 text-gray-200 text-3xl">SAMPLE PROJECTS</div>
      </HorizontalBar>
      <div className="mt-4">
        <img className="w-full block" src="/images/project-vrp.png" alt="project vrp"></img>
        <div className="text-white text-center pt-6 px-10 pb-10" style={{backgroundColor: color}}>
          <div className="mb-10 max-w-screen-md mx-auto">
            <h2 className="text-3xl text-bold my-3">Customized Routing Solutions</h2>
            <p>We provide customized routing solutions that handle your routing and scheduling needs. These include time-dependent routing, edge and node routing together, inventory routing, dynamic reassignments, fleet management, etc.</p>
          </div>
          <HighlightPhone color1="#fff" color2={color} />
        </div>
      </div>
    </div>
  );
}

export default ProjectVRP;
