
import React from "react";

import HighlightPhone from './common/HighlightPhone';

function SubProject(props) {
  return (
    <div className="w-full border-2 border-white px-6 mt-24">
      <div className="relative -top-20">
        <img className="block mx-auto" alt="screenshot" src={props.src}></img>
        <h2 className="relative top-6 text-2xl text-bold">{props.title}</h2>
        <div className="relative top-12 text-justify">
          {props.children}
        </div>
      </div>
    </div>
  );
}

function ProjectML() {
  const color = "#7e7d7f";

  return (
    <div className="app-max-width">
      <div className="mt-10">

        <img className="w-full block" src="/images/project-ml.png" alt="project ml"></img>

        <div className="text-white text-center pt-6 px-10 pb-10" style={{backgroundColor: color}}>
          <div className="max-w-screen-md mx-auto">
            <div className="mb-10">
              <h2 className="text-3xl text-bold my-3">Customized Machine Learning Solutions</h2>
              <p className="mb-2">Machine learning enables systems to learn from data and perform predictive analytics rather than through hard coding. We develop customized algorithms to ingest the training data to produce more precise outputs based on the dataset. In such solutions, we leverage big data to better understand subtle changes in behavior, preferences, customer satisfaction or bring to light the hidden patterns and anomalies embedded in the data. Below are samples of our work using our customized machine learning approaches.</p>
            </div>

            <SubProject src="/images/project-ml-0.png"
              title="Prediction of Yield and Price of Pineapples">
              <p className="mb-2">We study the process of growing pineapples and the behavior of farmers in Thailand as well as the constituents to produce canned pineapples for the client.</p>
              <p className="mb-2">We collected all the relevant data with regards to weather, sunshine, rainfall, volume, prices, and related government policies and derive a forecasting system to predict production volume and price of pineapple in Thailand. Pineapple prices can be as low as 2 baht and as high as 16 baht. Having a good model that looks ahead 12-24 months will enable the client to derive much better strategies to manage their costs and increase their market share.</p>
            </SubProject>

            <SubProject src="/images/project-ml-1.png"
              title="Asset Tracking and Load Status Prediction">
              <p className="mb-2">This project aims to improve operations efficiency at the airport. In this engagement, we helped the Civil Aviation Authority of Singapore (CAAS) to track the non-motorized equipment at the airport.</p>
              <p className="mb-2">At present, only a low-powered passive tag is attached to each piece of non-motorized equipment. Through the use of big data and machine learning, we are able to achieve good enough accuracies in terms of equipment locations and load statuses.</p>
              <p className="mb-2">In the next phase, we are working with CAAS, together with SATS and DNATA, to create a digital twin to track and optimize the operations and at the same time, run simulations with regards to different tactical policies and strategies.</p>
            </SubProject>

            <HighlightPhone color1="#fff" color2={color} />

          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectML;
