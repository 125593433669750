
import { React, useState } from "react";

import './Spinner.css';

const FORMSPARK_ACTION_URL = "https://submit-form.com/u8cGit5M";

function ContactUs() {
  const commonInputClassName = "border-2 rounded-lg p-2 focus:outline-none";
  const [loading, setLoading] = useState(false);
  const [sendResult, setSendResult] = useState('');

  function sendBtnText() {
    if (loading) {
      return (
        <div className="inline-block relative top-1">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      );
    } else {
      return 'Send';
    }
  }

  function useInput(config) {
    const [value, setValue] = useState(config.defaultValue);
    const [hasError, setHasError] = useState(false);
    const input = <input
      className={`${commonInputClassName} ${hasError? 'border-red-700': 'border-gray-300'} ${config.className? config.className: ''}`}
      value={value} onChange={e => setValue(e.target.value)} type={config.type} placeholder={config.placeholder}></input>
    return [value, setHasError, input];
  }

  const [firstName, setFirstNameHasError, firstNameInput] = useInput({
    defaultValue: '',
    type: 'text',
    placeholder: 'First Name',
  });

  const [lastName, setLastNameHasError, lastNameInput] = useInput({
    defaultValue: '',
    type: 'text',
    placeholder: 'Last Name',
  });

  const [email, setEmailHasError, emailInput] = useInput({
    defaultValue: '',
    type: 'text',
    placeholder: 'Email',
    className: 'md:col-span-2',
  });

  const [phone, setPhoneHasError, phoneInput] = useInput({
    defaultValue: '',
    type: 'text',
    placeholder: 'Phone',
  });

  const [country, setCountryHasError, countryInput] = useInput({
    defaultValue: '',
    type: 'text',
    placeholder: 'Country',
  });

  const [receiveNews, setReceiveNews] = useState(true);
  const [message, setMessage] = useState('');
  const [messageHasError, setMessageHasError] = useState(false);

  function onClickSend() {
    let firstNameError = firstName.trim().length === 0;
    setFirstNameHasError(firstNameError);

    let lastNameError = lastName.trim().length === 0;
    setLastNameHasError(lastNameError);

    let emailError = email.trim().length === 0;
    setEmailHasError(emailError);

    let phoneError = phone.trim().length === 0;
    setPhoneHasError(phoneError);

    let messageError = message.trim().length === 0;
    setMessageHasError(messageError);

    if (firstNameError || lastNameError || emailError || phoneError || messageError) {
      console.log('has errors');
    } else {
      send({
        firstName,
        lastName,
        email,
        phone,
        country,
        receiveNews,
        message,
      });
    }
  }

  function send(body) {
    setLoading(true);
    setSendResult('');

    fetch(FORMSPARK_ACTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
    .then(res => {
      setLoading(false);
      setSendResult('Message sent.');
    }, err => {
      setLoading(false);
      setSendResult('Message failed to send, please contact administrator.');
    });
  }

  return (
    <div className="app-max-width">
      <div className="mt-10">
        <a id="contact-us-form">
        <img className="w-full block" src="/images/contact-us.png" alt="contact us"></img>
        </a>
        <div className="text-gray-700 pt-6 px-10 pb-10 max-w-screen-md mx-auto">
          <div className="text-red-800 text-left mb-2">{sendResult}</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {firstNameInput}
            {lastNameInput}
            {emailInput}
            {phoneInput}
            {countryInput}
            <textarea className={`${commonInputClassName} md:col-span-2 ${messageHasError? 'border-red-700': 'border-gray-300'}`}
              placeholder="Message" rows="5" defaultValue={message} onChange={e => setMessage(e.target.value)}>
            </textarea>
            <div className="md:col-span-2 text-left cursor">
              <input type="checkbox" id="receive-news" name="receive-news" className="mr-2" defaultChecked={receiveNews}
                onChange={() => setReceiveNews(!receiveNews)}></input>
              <label htmlFor="receive-news">By checking on this button, you agree to receive communications from Red Jasper holdings.</label>
            </div>
          </div>

          <div className="text-left my-2">Please read our <a className="underline" href="/privacy">Privacy Policy</a>.</div>

          <div className="text-right">
            <button className="border rounded px-6 py-2 text-red-800 bg-gray-300 hover:bg-gray-200 hover:text-red-700 focus:outline-none disabled:opacity-50 text-lg"
              onClick={onClickSend} disabled={loading}>
              {sendBtnText()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
