
function Privacy() {

  const pClassName = 'mb-4';

  return (
    <div className="text-gray-600 text-justify max-w-screen-md mx-auto mb-10 px-3">
      <h2 className="text-2xl mt-10 mb-6">Our Privacy Policy</h2>
      <p className={pClassName}>This website is owned and operated by Red Jasper Holdings Pte. Ltd. (Singapore Company Registration Number: 201833647K), hereinafter referred to as ”Red Jasper”. This Privacy Policy (the “Policy”) applies to information that we collect through the Red Jasper Website (the “Site” which consists of all content and pages located within the redjasper.com web domain), information provided during telephone and other forms of communications. </p>
      <p className={pClassName}>For the purposes of this Policy, “Personal Data” refers to your personal data and any third party’s personal data. We may keep a log of the IP address, operating system, and browser software used by each user of the Site, and we may be able to determine a user’s Internet Service Provider and/or the geographical location of the user’s point of connectivity from an IP address. To collect this information, various web analytics tools may be used. </p>
      <p className={pClassName}>As a responsible organization, Red Jasper is committed to maintaining a high standard of confidentiality with respect to the Personal Data of our clients in accordance with the requirements of the Personal Data Protection Act 2012 (the “PDPA”), Singapore. For more information about the PDPA, please visit the Personal Data Protection Commission’s website at https://www.pdpc.gov.sg.</p>
      <p className={pClassName}>By reading this Policy and submitting any information herein, you hereby agree to this Policy and consent to our collection, use, and disclosure of your Personal Data.</p>
      <p className={pClassName}>Personal Data</p>
      <p className={pClassName}>“Personal Data” is defined in the PDPA to mean personal information, whether true or not and whether in electronic or other form, about an individual who can be identified:</p>
      <p className={pClassName}>1. from that data; or<br/>
        2. from that data and other information to which we have access to or are likely to have access to.<br/>
        Examples of personal data are your name, address, NRIC/FIN/Passport number, photograph or video image, telephone numbers and email addresses.</p>
      <p className={pClassName}>Personal Data excludes Business Contact Information which means an individual’s name, position name or title, business telephone number, business address, business electronic mail address or business fax number and any other similar information about the individual, not provided by the individual solely for his personal purposes.</p>
      <p className={pClassName}>Privacy Concerns</p>
      <p className={pClassName}>If you are concerned about the handling of your Personal Data, or if you have any complaints or queries related to your Personal Data or this Policy, please contact:</p>
      <p className={pClassName}>Red Jasper Holdings Pte Ltd<br/>
      237 Pandan Loop #08-09<br/>
      Singapore 128 424<br/>
      Email: brendakarli@redjasper.com<br/>
      Effective Date: 1 January 2021</p>
    </div>
  );
}

export default Privacy;
