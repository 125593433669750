import { React, useState, useEffect } from "react";

function Header(props) {

  const [menuShown, setMenuShown] = useState(false);
  const items = [
    {text: 'Spin-offs', href: '/#spin-offs'},
    {text: 'Projects', href: '/#projects'},
    {text: 'Consulting', href: '/#consulting'},
    {text: 'About Us', href: '/#about-us'},
  ];

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768) {
        setMenuShown(true);
      }
      if (window.innerWidth < 768) {
        setMenuShown(false);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let navClassName = "flex flex-col-reverse text-left mt-5 mb-2 md:mt-0 md:mb-0 md:flex-grow md:h-16 md:flex-row-reverse text-gray-600";
  if (! menuShown) {
    navClassName += " hidden";
  }

  return (
    <header className="app-max-width p-3">
      <div className="container mx-auto relative">
        <div className="flex flex-col md:mx-2 md:flex-row">
          <div className="h-16 md:flex-none w-40">
            <a href="/">
              <img src="redjasper_logo.png" alt="logo" />
            </a>
          </div>
          <nav className={navClassName}>
            {items.map((item, i) =>
              <a key={i} className="text-lg hover:text-red-900 py-1 pl-3 md:pl-6 md:py-4" href={item.href}>{item.text}</a>
            )}
          </nav>
        </div>
        <button className="block md:hidden absolute top-4 right-2"
          onClick={() => setMenuShown(!menuShown)}>
          <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"> <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" /></svg>
        </button>
      </div>
    </header>
  );
}

export default Header;
