import React from "react";

function HorizontalBar(props) {
  return (
    <div className={props.className}>
      <div className="h-12" style={{backgroundColor: props.color}}>
        {props.children}
      </div>
      <div className="w-full">
        <svg className="w-12 mx-auto" viewBox="0 0 250 130">
          <polygon
            points="0 0, 250 0, 125 130"
            style={{
              fill: props.color,
              strokeWidth: 0,
            }}
          />
        </svg>
      </div>
    </div>
  );
}

export default HorizontalBar;
