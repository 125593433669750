
import React from "react";

function Research() {
  const all = `
  “Algorithmic Complexity of Color Switching," Y.M. Chee and A. Lim, Information Processing Letters, 43 (1992) 63-68.

  “Optimal Joining Of Compacted Cells," A. Lim, S. Cheng and Sartaj Sahni, IEEE Transactions on Computers, May 1993, Vol 42, #5, 597-607.

  “Single Jog Minimum Area Joining of Compacted Cells," A. Lim and Y.M. Chee and S. Cheng, Information Processing Letters, 47 (1993) 167-172. .

  “On the Circuit Implementation Problem," W.N. Li, A. Lim, P. Agrawal and Sartaj Sahni, IEEE Transactions on Computer-Aided Design of Integrated Circuits and Systems, Vol 12, #8 (1993), 1147-1156.

  “Segmented Winner Trees," A. Lim and S. Sahni, Journal of Information Processing and Cybernetics, Vol 30, No. 1 (1994), 29-43.

  “The Role of Long Short Paths in Circuit Performance Optimization," with S. Cheng, H. Chen, D. Du and A. Lim, IEEE Transactions on Computer-Aided Design of Integrated Circuits and Systems, Vol 13, No 7 (1994), 854-864.

  “Minimum Area Joining of K Compacted Cells", A. Lim, Information Processing Letters, Vol 58 (1996), 265-269. .

  “A Linear Time Algorithm to Test Planar Topological Routability," A. Lim, V. Thanvantri and S. Sahni, IEEE Transactions on Computer-Aided Design of Integrated Circuits and Systems, Vol 16 (1997), No 6, 651-656.

  “Joining of Compacted Cells Using Genetic Algorithm", H. Chew and A. Lim, IEE Electronic Letters, Vol 33 (1997), No 23, 1944-1945.

  “Wafer Packing Using Full Mask Exposure Fabrication", D. Du, A. Lim and C. Wu, IEE Electronic Letters, Vol 34 (1998), No 7, 659-660.

  “A Computer-Aided Product Redesign System for Robotics Assembly", W. Hsu, C.S.G. Lee and A. Lim, Robotica, Special issue on Intelligent Robotic Assembly, Vol 16 (1998), 239-249.

  “The Berth Planning Problem", A. Lim, Operations Research Letters, Vol 22, No 2-3 (1998), 105-110.

  “Page Access Scheduling in Join Processing", A. Lim, J. Kwan and W. Oon, Data and Knowledge Engineering, vol 37, No 3 (2001) 267-284.

  “Online Judge", with A. Kurnia, A. Lim and B. Cheang, Computers and Education, 36 (2001) 299-315.

  “A Hybrid Algorithm for the Vehicle Routing Problem", J. Ang, W. Ho, and A. Lim, International Journal on Artificial Intelligence Tools 10(3) 431-449 (2001).

  “A Matching Based Algorithm For Page Access Sequencing in Join Processing", A. Lim, W. Oon and C. Chi, Journal of Systems and Software 60 (2002) 11-19.

  “Automated Campus-Wide Examination Timetabling at the National University of Singapore”, A. Lim, J. Ang, W. Ho and W. Oon, Practice Abstract, Interfaces, volume 32, number 6 (2002) 43-44.

  “Sexual Selection For Genetic Algorithms", K. Goh, A. Lim and B. Rodrigues, AI Review, Volume 19, number 2, 123-152 (2003 April).

  “Local Search with Annealing-like Restarts to solve the VRPTW", H. Li and A. Lim, European Journal on Operational Research, Volume 150, No. 1 (2003 October),  115-127.

  “A Meta-heuristic for the pickup and delivery problem with time windows", H. Li and A. Lim, International Journal on Artificial Intelligent Tools, Volume 12, Number 2 (2003) 173-186.

  “On Automated Grading of Programming Assignments in an Academic Institution”, B. Cheang, A. Kurnia, A. Lim, W. Oon, Computers and Education 41 (2003 September) 121-131.

  “Nurse Rostering Problems – A Bibliographic Survey”, B. Cheang, H. Li, A. Lim, B. Rodrigues, European Journal of Operational Research, Invited Review, Volume 151, No 3 (2003 December), 447-460.

  “A Multifaced Buildup Algorithm for Three Dimensional Packing”, A. Lim, B. Rodrigues, Y. Wang, Omega (2003), Volume 31 (6), 471-481.

  “Meta-Heuristics with Local Search Techniques for Retail Shelf Space Optimization”, A. Lim, B. Rodrigues, X Zhang, Management Science, Volume 50, No. 1, January 2004, pp. 117-131.

  “Crane Scheduling with Spatial Constraints", A. Lim, B. Rodrigues, F. Xiao, Y. Zhu, Naval Research Logistics, Volume 51, Issue 3, April 2004, 386-406.

  “New Heuristics for the Over-constrained Flight to Gate Assignment”, H. Ding, A. Lim, B. Rodrigues, Y. Zhu, Journal of the Operational Research Society (2004) Volume 55, Number 7, 760-768.

  “Manpower Allocation Problem with Time Windows”, A. Lim, B. Rodrigues, Lei Song, Journal of the Operational Research Society, Volume 55, Number 11 (2004), 1178-1186.

  “Crossdocking – JIT Scheduling with Time Windows”, Y. Li, A. Lim, B. Rodrigues, Journal of the Operational Research Society, December 2004, Volume 55, Number 12 (2004), 1342-1351.

  “Port Yard Optimization Problem”, P. Chen, Z. Fu, A. Lim and B. Rodrigues, IEEE Transactions on Automation Science and Engineering (2004) July, Volume 1, No 1, 26-37.

  “The Two-Dimensional Packing Problem for Irregular Objects”, P. Chen, Z. Fu, A. Lim and B. Rodrigues, International Journal on Artificial Intelligent Tools, volume 13, number 3, September 2004, 429-448.

  “A Hybrid Framework for Over-Constrained Generalized Resource Project Scheduling Problems”, A. Lim, B. Rodrigues, R. Thangarajoo, F. Xiao, Artificial Intelligence Review, June 2004, Volume 22, Issue 3, 211-243.

  “Minimizing Total Flow Time in Single Machine Environment with Release Time: An Experimental Analysis”, Y Guo, A. Lim, B. Rodrigues, S. Yu, Computers and Industrial Engineering, Volume 47, Issues 2-3, Pages 123-140 (November 2004).

  “The Over-Constrained Airport Gate Assignment Problem”, A. Lim, B. Rodrigues, Y. Zhu, Computers and Operations Research, Volume 32, Issue 7, July 2005, 1867-1880.

  “3D Container Packing Heuristics”, A. Lim, B. Rodrigues, Y. Yang, Applied Intelligence, Vol. 22, No. 2 (March/April 2005), 125-134.

  “k-Center problems with minimum coverage,” A. Lim, B. Rodrigues, F. Wang, Z. Xu, Theoretical Computer Science, volume 332, Issues 1-3, February 2005, 1-17.

  “Manpower Allocation with Time Windows and Job Teaming Constraints”, Y. Li, A. Lim and B. Rodrigues, Naval Research Logistics, Volume 52 (4), June 2005, 302-311.

  "Airport Gate Scheduling with Time Windows", Y. Zhu, A. Lim and B. Rodrigues, Artificial Intelligence Review, Volume 24, Number 1, September 2005, 5-31.

  “Tabu Search embedded Simulated Annealing for the Shortest Route Cut and Fill Problem,” A. Lim, B. Rodrigues, J. Zhang, Journal of Operational Research Society, Volume 56, Number 7, 816-825, 2005.

  “A Note on the Optimal EOQ for Announced Price Increases in Infinite Horizon,” A. Lim and B. Rodrigues, Operations Research, Volume 53, No. 4, July-August 2005, 731-732.

  "Multi-depot Vehicle Routing Problem: two-stage and one-stage approach", A. Lim and F. Wang, IEEE Transactions on Automation Science and Engineering, volume 2, No 4, October 2005, 397-402.

  “Transshipment through Crossdocks with Inventory and Time Windows”, A. Lim, Z. Miao, B. Rodrigues, Z. Xu, Naval Research Logistics, Volume 52, Issue 8 December 2005, 724-733.

  “Multi-Crossdocks with Inventory and Time Windows”, P. Chen, Y. Guo, A. Lim, B. Rodrigues, Computers & Operations Research, Volume 33, Issue 1, January 2006, Pages 43-63.

  “The Bottleneck Problem with Minimum Quantity Commitment”, A. Lim and Z. Xu, Naval Research Logistics, Volume 53, Issue 1, 2006, 91-100.

  “A Hybrid Genetic Algorithm for the Three-Index Assignment Problem,” G. Huang and A. Lim, European Journal of Operational Research 172 (2006) 249-257.

  "Heuristics for A Bidding Problem,” Y. Guo, A. Lim, B. Rodrigues, Y. Zhu, Computers & Operations Research, Volume 33, Issue 8, August 2006, pages 2179-2188.

  “Ant Colony Optimization with Hill climbing to solve the Bandwidth Minimization Problem,” A. Lim, J. Lin, B. Rodrigues, F. Xiao, Applied Soft Computing, Volume 6, Issue 2, January 2006, Pages 180-188.

  “A Transportation Problem with Minimum Quantity Commitments,” A. Lim, F. Wang, and Z. Xu, Transportation Science, Vol 40, No. 1, Feb 2006, pp. 117-129.

  “Using A Lagrangian Heuristic For A Combinatorial Auction Problem,” Y. Guo, A. Lim, B. Rodrigues, J. Tang, International Journal on Artificial Intelligence Tools, Vol. 15, No. 3 (2006) 481-489.

  “Heuristics for Matrix Bandwidth Reduction”, A. Lim, B. Rodrigues, F. Xiao, European Journal of Operational Research 174 (2006) 69-91.

  “A Critical-Shaking Neighborhood Search for the Yard Allocation Problem,” A. Lim and Z. Xu, European Journal of Operational Research, 174 (2006) 1247-1259.

  “Indexing Graph-Structured XML Data for Efficient Structural Join Operation,” Q. Chen, A. Lim, K. Ong, J. Tang, Data and Knowledge Engineering, 58 (2006) 159-179.

  “A Simulated Annealing with Hill Climbing Approach to solve the Traveling Tournament Problems”, A. Lim, B. Rodrigues, X. Zhang, European Journal of Operational Research, 174 (2006), 1459-1478.

  “Indexing XML Documents for XPath Query Processing in External Memory,” Q. Chen, A. Lim, K. Ong, J. Tang, Data and Knowledge Engineering, 59 (2006) 681-699.

  “Scheduling sports competitions at multiple venues - Revisited”, A. Lim, B. Rodrigues, X. Zhang, European Journal of Operational Research, 175 (2006), 171-186.

  “Two Machine Flow Shop Problems with a Single Server,” A. Lim, B. Rodrigues, C. Wang, Journal of Scheduling (2006) 9, 515-543.

  “Carrier Assignment Models Transportation Procurement,” Y. Guo, A. Lim, B. Rodrigues, Y. Zhu, Journal of Operational Research Society (2006) 57, 1464-1471.

  “Crane Scheduling with Non-Crossing Constraints”, Y. Zhu and A. Lim, Journal of Operational Research Society (2006) 57, 1472-1481.

  “The One-Commodity Pickup and Delivery Travelling Salesman Problem on a Path or a Tree,” A. Lim, F. Wang, Z. Xu, Networks, Volume 48, Issue 1 (August 2006) 24-35.

  “A m−Parallel Crane Scheduling Problem With A Non-Crossing Constraint,” A. Lim, B. Rodrigues, Z. Xu, Naval Research Logistics, Volume 54, Issue 2, Pages 115-235 (March 2007).

  “A Stochastic beam search for the berth allocation problem,” F. Wang and A. Lim, Decision Support Systems, 42(4): 2186-2196 (2007).

  “Port Space Allocation in Two Dimensions with Time Constraints,” Z. Fu, Y. Li, A. Lim and B. Rodrigues, Journal of the Operational Research Society, Volume 58, Number 6, June 2007 , pp. 797-807(11).

  “A Local Search Using Solution Fragments for the 2-Machine Biciteria Scheduling Problem,” G. Huang, A. Lim, B. Rodrigues, Computational Optimization and Applications, Volume 37, Issue 2 (June 2007) Pages: 219 – 229.

  “Minimizing The Makespan of Unrelated Parallel Machines,” Y. Guo, A. Lim, B. Rodrigues and L. Yang, International Journal on Artificial Intelligence Tools, Vol. 16, No. 3 (2007) 399-415.

  “A Fast Algorithm for Bandwidth Minimization,” A. Lim, B. Rodrigues, F. Xiao. International Journal on Artificial Intelligence Tools, Vol. 16, No. 3 (2007) 537-544.

  “Global Sourcing Using Local Content Tariff Rules,” Y. Li, A. Lim, B. Rodrigues, IIE Transactions, Volume 39, Issue 5 May 2007, 425-437.

  “Particle Swarm Optimization and Hill Climbing to Solve the Bandwidth Minimization Problem”, A. Lim, Jing Lin, and Fei Xiao, Applied Intelligence, Volume 26, Number 3 / June, 2007, 175-182.

  “A Two-Stage Heuristic with Ejection Pools and Generalized Ejection Chains for the Vehicle Routing Problem with Time windows”, A. Lim and X. Zhang, Informs Journal on Computing, Volume 19, Number 3, 443-457.

  “Machine Scheduling Performance with Maintenance and Failure,” A. Lim, B. Rodrigues, S. Yu, Mathematical and Computer Modelling, Vol 45 (2007) 1067-1080.

  "Enabling structural summaries for efficient update and workload adaption," Q. Chen, A. Lim, K. Ong, Data and Knowledge Engineering,  volume 64, number 3, 558-579 (2008).

  “The Selection and Assignment Problem with Minimum Quantity Commitments”, A. Lim, Z. Xu, and F. Wang, Journal of the Operational Research Society, 59, 69-702, May 2008.

  “Transportation Procurement with Seasonally-Varying Demand,” A. Lim, B. Rodrigues, Z. Xu, Operations Research, May-June 2008; 56: 758 - 771.

  “An Effective Local Search Method to solve the Flexible Demand Assignment Problem,” F. Wang, A. Lim, IEEE Transactions on Automation Science and Engineering, Volume 5,  Issue 2,  April 2008 Page(s):289 - 297

  “Tariff Concession in Production Sourcing,” Y. Guo, Y. Li, A. Lim, B. Rodrigues, European Journal of Operational Research, Volume 187, Issue 2, 1 June 2008, Pages 543-555.

  “Truck Dock Assignment Problem with Operational Time Constraint within Crossdocks,” A. Lim, H. Ma, and Z. Miao, European Journal of Operational Research, 192 (2009) 105-115.

  “Searching Optimal Re-sequencing and Feature Assignment on an Automated Assembly Line,” A. Lim and Z. Xu, Journal of the Operations Research Society, 60, 2009, 361-371.

  “Assessing the qualitative influence of journals in a citation network,” A. Lim, H. Ma, Vendy Wen, Z. Xu, B. Cheang, Communications of the ACM, August 2009, Vol 52, No. 8, 111-116.

  "Combined Pricing and Inventory Control for a Perishable Product with Two-Period Lifetime," Y. Li, A. Lim, B. Rodrigues, Manufacturing and Service Operations Management, Vol 11, No. 3, Summer 2009, pp 538-542.

  “Load Balancing in Project Assignment,” Z. Liang, S. Guo, Y. Li, and A. Lim, Computers and Operations Research, Volume 37, Issue 12, December 2010, Pages 2248-2256.

  “Crossdocking Distribution Network with Setup Cost and Time Window Constraint,” H. Ma, Z. Miao, A. Lim, B. Rodrigues, Omega – International Journal of Management Science, Vol. 39, No. 1, pp. 64-72, January 2011.

  “New Concepts for Activity Float in Resource Constrained Project Management,” A. Lim, H. Ma, B. Rodrigues, S. Tan, F. Xiao, Computers and Operations Research, Volume 38, Issue 6, June 2011, Pages 917-930.

  “The Tree Representation for the Pickup and Delivery Traveling Salesman Problem with LIFO Loading,” Y. Li, A. Lim, H. Qin, and T. Du, European Journal on Operational Research, Volume 212, Issue 3, page 482-496, 2011

  “The Multiple Container loading Cost Minimization Problem”, C. Che, W. Huang, A. Lim, W. Zhu, European Journal on Operational Research, Volume 214 (2011), 501-511.

  “Evaluating OR/MS Journals via PageRank,” Z. Xu, B. Cheang, A. Lim, Q. Wen, Interfaces, Volume 41, No 4, July–August 2011, 375-388.

  “Robust Logistics Network Design with facility disruption,” P. Peng, L. Snyder, A. Lim, Z. Liu, Transportation Research Part B, Volume 45, Issue 8, September 2011, Pages 1190-1211.

  “A Skyline Heuristic for the 2D Rectangular Packing and Strip Packing Problems,” Wei Lijun, W. Zhu, W. Oon, A. Lim, European Journal on Operational Research, Volume 215, Issue 2, 1 December 2011, Pages 337-346.

  “Runtime analysis of (1+1) evolutionary algorithm for two combinatorial optimization instances”, Y. Zhang, Z. Hao, H. Huang, A. Lim, Journal of Information and Computational Science, 2011, 8(15), 3497-3506.

  “An Investigation into the Vehicle Routing Problem with Time Window and Link Capacity Constraints,” H. Ma, B. Cheang, A. Lim, L. Zhang, Y. Zhu, Omega – International Journal of Management Science, Volume 40, Issue 3, June 2012, Pages 336-347.

  “An Algorithm for the Freight Allocation Problem with All-Units Quantity-Based Discount,” H. Qin, MF Luo, X. Gao, A. Lim, Omega – International Journal of Management Science, Omega 40, Issue 4, August 2012,  415–423.

  “Arboricity: an acyclic hypergraph decomposition problem motivated by database theory,”  Y. M. Chee, L. Ji, A. Lim, and A. K. H. Tung, Discrete Applied Mathematics, 160 (2012) pp. 100-107

  “The Freight Allocation Problem with Lane Cost Balancing Constraint,” A.Lim, H. Qin, Z. Xu, European Journal on Operational Research, 217(1): 26-35 (2012).

  “Example-based Learning Particle Swarm Optimization for Continuous Optimization,” Han Huang, Hu Qin, Zhifeng Hao, Andrew Lim.  Information Sciences, 182(1): 125-138 (2012)

  “An Iterated Construction Approach with Dynamic Prioritization for Solving the Container Loading Problems,” A. Lim, H. Ma, J. Xu, X. Zhang, Expert Systems With Applications, Volume 39, Issue 4, March 2012, Pages 4292-4305.

  “A Two-stage Tabu Search Algorithm with Enhanced Packing Heuristics for the 3L-CVRP and M3L-CVRP,” W. Zhu, H. Qin, A. Lim, L. Wang, Computers and Operations Research, volume 39, number 9, 2178-2195 (2012).

  “Grocery Perishables Management,” Y. Li, B. Cheang, A. Lim, Production and Operations Management, Vol 21, No. 3, June 2012, 504–517.

  "The Single Vehicle Routing Problem with Toll-by-Weight Scheme: a Branch and Bound Approach", Zizhen Zhang, Hu Qin, Andrew Lim and Wenbin Zhu, European Journal of Operational Research, Volume 220, No. 2, July 2012,295-304.

  “A Reference Length Approach for the 3D Strip Packing Problem”, L. J. Wei, W.C. Oon, W. B. Zhu, A. Lim, European Journal of Operational Research, vol. 220, No. 1, 2012, 37-47.

  “An Investigation of IDA* Algorithms for the Container Relocation Problem”, W.B. Zhu, H. Qin, A. Lim, H.D. Zhang, IEEE Transactions on Automation Science and Engineering, Volume 9, No. 4, October 2012, 710-722.

  “A New Iterative-Doubling Greedy-Lookahead Algorithm for the Single Container Loading Problem”, W.B. Zhu, A. Lim, European Journal of Operational Research, Volume 222, Issue 3, November 2012, 408-417.

  “Space Defragmentation for Packing Problems”, W.B. Zhu, Z. Y. Zhang, W.C. Oon, A. Lim, European Journal of Operational Research, Volume 222, Issue 3, November 2012, 452-463.

  "A Prototype Column Generation Strategy for the Multiple Container Loading Problem", W.B. Zhu, W.L. Huang, A. Lim, European Journal of Operational Research, Volume 223, Issue 1, November 2012, 27-39.

  "Multiple Pickup and Delivery Traveling Salesman Problem with Last-in-first-out Loading and Distance Constraints", B. Cheang, X. Gao, A. Lim, H. Qin and W.B. Zhu, European Journal of Operational Research, Volume 223, Issue 1, November 2012, 60-75.

  “The Six Elements to Block-Building Approaches for the Single Container Loading Problem”, W.B. Zhu, W. C. Oon, A. Lim, Y. J. Weng, Applied Intelligence, 37(3): 431-445 (2012).

  “Goal-driven Approach to the 2D Bin Packing and Variable-sized Bin Packing Problems”, L.J. Wei, W.B. Zhu, W.C. Oon, A. Lim, European Journal of Operational Research, Volume 224, Issue 1, 1 Jan 2013, Pages 110–121.

  “New Meta-heuristics for the Resource-Constrained Project Scheduling Problem,” A. Lim, H. Ma, B. Rodrigues, S. Tan, F. Xiao, Flexible Services and Manufacturing Journal, June 2013, Volume 25, Issue 1-2, pp 48-73.

  “A Memetic Algorithm for the Multiperiod Vehicle Routing Problem with Profit,” Z. Zhang, C. Che, B. Cheang, A. Lim, H. Qin, European Journal of Operational Research,  Volume 229, Issue 3, 2013, Pages 573-584.

  “An Adaptive Ejection Pool with Toggle-Rule Diversification Approach for the Capacitated Team Orienteering Problem,” Z. Luo, B. Cheang, A. Lim, W. Zhu, European Journal of Operational Research, 2013, Volume 229, Issue 3, Pages 673-682.

  “The Single Container Loading Problem with Axle Weight Constraints,” A .Lim, H. Ma, C. Qiu, W. Zhu, International Journal of Production Economics, Volume 144, Issue 1, July 2013, Pages 358-369.

  “A Multi-Round Beam Search Approach for the Single Container Loading Problem with Shipment Priority”, N. Wang, W Zhu, A. Lim, International Journal of Production Economics, Volume 145, Issue 2, October 2013, Pages 531–540.

  “An Iterative 3-Component Heuristic for the Team Orienteering Problem with Time Windows,” Q. Hu, A. Lim, European Journal of Operational Research, Volume 232, Issue 2, 16 January 2014, Pages 276–286.

  “An Improved Approximation Algorithm for the Capacitated TSP with Pickup and Delivery on a Tree”, Z. Xu, X. Lai, A. Lim, F. Wang, Networks 63(2), March 2014, 179-195.

  “A Memetic Algorithm for the Capacitated m-Ring-Star Problem,” Z. Zhang, H. Qin, A. Lim, Applied Intelligence, March 2014, Volume 40, Issue 2, pp 305-321.

  “The freight consolidation and containerization problem,” H. Qin, Z. Zhang, Z. Qi, A. Lim, European Journal of Operational Research, Issue 1, Volume 234, April 2014, 37-48.

  “Branch-and-Price-and-Cut for the Multiple Traveling Repairman Problem with Distance Constraints,” Z. Luo, A. Lim, H. Qin, European Journal of Operational Research, Issue 1, Volume 234, April 2014, 49-60.

  “An evolutionary algorithm based on constraint set partitioning for nurse rostering problems,” H Huang, W Lin, Z Lin, Z Hao, A Lim, Neural Computing and Applications, Volume: 25 Issue: 3-4 Pages: 703-715, Sep 2014.

  “OR/MS Journals Evaluation Based on a Refined PageRank Method: An Updated and More Comprehensive Review”, B. Cheang, S. Chu, C. Li, A. Lim, Scientometrics, August 2014, Volume 100, Issue 2, pp 339-361.

  “An adaptive neighborhood search algorithm for a heterogeneous vehicle routing problem with three-dimensional loading constraints,” L. Wei, Z. Zhang, A. Lim, IEEE Computational Intelligence, October 2014, Volume 9, number 4, 18-30.

  “A Block-based Layer Building Approach for the 2D Guillotine Strip Packing Problem,” L. Wei, T. Tian, W. Zhu, A. Lim, European Journal of Operational Research, Volume 239, Issue 1, 16 November 2014, Pages 58-69.

  “A Multidimensional Approach to Evaluating Management Journals: Refining PageRank via the Differentiation of Citation Types and Identifying the Roles that Management Journals Play,” B. Cheang, S. Chu, C. Li, A. Lim, Journal of the Association for Information Science and Technology (formerly the Journal of the American Society for Information Science and Technology), Volume 65, Issue 12, pages 2581–2591, December 2014

  “The two-dimensional vector packing problem with piecewise linear cost function”, Q. Hu, A. Lim, W. Zhu, Omega, Volume 50, January 2015, Pages 43-53.

  “Solving the Container Relocation Problem by an Improved Greedy Look-ahead Algorithm”, B. Jin, A. Lim, W. Zhu, European Journal of Operational Research, Volume 240 issue 3, February 2015, 837-847.

  “Identifying Future Defaulters: A Hierarchical Bayesian Method,” F. Liu, ZS Hua, A. Lim, European Journal of Operational Research, Volume 241, Issue 1, February 2015, Pages 202-211.

  “A goal-driven prototype column generation strategy for the multiple container loading cost minimization problem,” L. Wei, W. Zhu, A. Lim, European Journal of Operational Research, Volume 241, Issue 1, Feb 2015, Pages 39-49.

  “A bidirectional building approach for the 2D constrained guillotine knapsack packing problem,” L. Wei and A. Lim, European Journal of Operational Research, Volume 242, Issue 1, 1 April 2015, Pages 63-71.

  “Identifying Patterns and Structural Influences in the Scientific Communication of Business Knowledge,” B. Cheang, C. Li, A. Lim, Z. Zhang, Scientometrics, Volume 103, issue 1, April 2015, Pages 159-189.

  “Target guided algorithms for the container pre-marshalling problem,” N. Wang, B. Jin, A. Lim, Omega, Volume 53, June 2015, Pages 67-77.

  “On Service Consistency in Multi-Period Vehicle Routing,” Z. Luo, H. Qin, C. Che, A. Lim, European Journal of Operational Research, Volume 243, Issue 3, 16 June 2015, Pages 731-744.

  “A variable neighborhood search for the capacitated vehicle routing problem with two dimensional loading constraints,” L. Wei, Z. Zhang, D. Zhang, A. Lim, European Journal of Operational Research, Volume 243, Issue 3, 16 June 2015, Pages 798-814.

  “Two Exact Algorithms for the Umpire Traveling Problem,” L. Xue, Z. Luo and A. Lim, European Journal on Operational Research, Volume 243, Issue 3, 16 June 2015, Pages 932-943.

  “A Memetic Algorithm for Patient Transportation”, Z. Zhang, M. Liu, A. Lim, OMEGA, Volume 54, July 2015, Pages 60–71.

  “A Tabu Search Algorithm for the Multi-period Inspector Scheduling Problem”, Q. Hu, W. Ming, Z. Zhang, Y. Xie, A. Lim, Computers and Operations Research, Volume 59, July 2015, Pages 78-93.

  “A Branch-and-Cut Algorithm for a Realistic Dial-a-Ride Problem,” Z. Luo, M. Liu, A. Lim, Transportation Research Part B, Volume 81, Part 1, November 2015, Pages 267–288.

  “An evolutionary local search for the capacitated vehicle routing problem minimizing fuel consumption under three-dimensional loading constraints,” Z. Zhang, L. Wei, A. Lim, Transportation Research Part B, Volume 82, December 2015, Pages 20-35

  “Multiple Container Loading with Preference,” T. Tian, W. Zhu, A. Lim, L. Wei, European Journal of Operational Research, Volume 248, Issue 1, 1 Jan 2016, Pages 84-94.

  “Adaptive Large Neighborhood Search Heuristics for the Vehicle Routing Problem with Stochastic Demands and Weight-related Cost,” Z. Luo, H. Qin, D. Zhang and A. Lim, Transportation Research Part E, Logistics and Transportation Review 01/2016, volume 85, 69-89.

  “Branch-and-price for the pickup and delivery problem with loading cost,” L. Xue, Z. Luo, A. Lim, OMEGA, Volume 59, Part B, March 2016, Pages 131-145.

  “A Fast Implementation for the 2D/3D box placement”, W. Zhu, Z. Luo, A. Lim, W. Oon, Computational Optimization and Applications, March 2016, Volume 63, Issue 2, pp 585-612.

  "Branch-and-Price-and-Cut for the Manpower Routing Problem with Synchronization Constraints", Z. Luo, H. Qin, W. Zhu, A. Lim, Naval Research Logistics, volume 63 (2), March 2016, 138-171.

  "Transportation service procurement problem with transit time", Q. Hu, Z. Zhang, A. Lim, Transportation Research Part B: Methodology, April 2016, Volume 86, April 2016, 19-36.

  "An Enhanced Branch-and-bound Algorithm for the Talent Scheduling Problem", Hu. Qin, Z. Zhang, A. Lim, X. Liang, European Journal of Operational Research, Volume 250, Issue 2, 16 April 2016, Pages 412–426.

  "Travel time analysis of the dual command cycle in the split-platform AS/RS with I/O dwell point policy", T. Liu, X. Xu, H. Qin and A. Lim, Flexible Services and Manufacturing Journal, Sept 2016, Volume 28, Issue 3, pp 442–460.

  “A feasibility-based heuristic for the container pre-marshalling problem”, N. Wang, B. Jin, Z. Zhang, A. Lim, European Journal of Operational Research, Volume 256, Issue 1, 1 January 2017, Pages 90–101.

  “Pickup and delivery service with manpower planning in Hong Kong Public Hospitals,” A. Lim, H. Qin, Z. Zhang, Transportation Science, Volume 51, Issue 2, May 2017, Pages 688-705.

  “Branch-and-price-and-cut for the Split-collection Vehicle Routing Problem with Time Windows and Linear Weight-related Cost,” Luo, H. Qin, W. Zhu, A. Lim Transportation Science, Volume 51(2), May 2017, Pg 668-687.

  "A branch-and-price algorithm for the two-dimensional vector packing problem with piecewise linear cost function", Q. Hu, W. Zhu, A. Lim, H. Qin, European Journal of Operational Research, Vol 260(1), 1 July 2017, Pages 70-80.

  “Pricing Strategies for Capacitated Ring-Star Problems based on Dynamic Programming Algorithms,” R Baldacci, A Hill, EA Hoshino, A Lim, European Journal of Operational Research, Volume 262(3), 1 Nov 2017, Pages 879-893.

  “The two-dimensional vector packing problem with general costs”, Q. Hu, L. Wei, and A. Lim, Omega-International Journal of Management Science, Volume 74, January 2018, Pages 59-69.

  “A branch-and-cut algorithm for the two-echelon capacitated vehicle routing problem with grouping constraints,” T. Liu, Z. Luo, H. Qin, A. Lim, European Journal of Operational Research, Vol 266(2), 16 April 2018, Pages 487-497.

  “A Greedy Aggregation-decomposition Method for Intermittent Demand Forecasting in Fashion Retailing,” C. Li and A. Lim, European Journal of Operational Research, Volume 269 (3), September 2018, 860-869.

  “A best-fit branch-and-bound algorithm for the unconstrained two-dimensional non-guillotine cutting problem,” L. Wei, Q. Hu, A. Lim, Q. Liu, European Journal of Operational Research, Volume 270 (2), October 2018, 448-474.

  “An adaptive selection approach for the 2D rectangle packing area minimization problem”, L. Wei, W. Zhu, A. Lim, Q. Liu, X. Chen, Omega-International Journal of Management Science, Volume 80, October 2018, 22-30.

  “A branch-and-price algorithm for scheduling of deteriorating jobs and flexible periodic maintenance on a single machine,” T. Wang, R. Baldacci, A. Lim, Q. Hu, European Journal of Operational Research, Volume 271, Issue 3, 16 December 2018, Pages 826-838.

  “A two-phase branch-and-price-and-cut for a dial-a-ride problem in patient transportation”, Z. Luo, M. Liu, A. Lim, Transportation Science, Volume 53, issue 1, Jan-Feb 2019, Pages 113-130.

  “Exact Algorithms for the Vehicle Routing Problem with Time Windows and Combinatorial Auction,” Z. Zhang, Z. Luo, H. Qin, A. Lim, Transportation Science, Volume 53(2), Mar-Apr 2019, Pages 427-441.

  “Multi-commodity Demand Fulfillment via Simultaneous Pickup and Delivery for a Fast Fashion Retailer,” Z Zhang, B Cheang, C Li, A Lim, Computers & Operations Research, 103:81-96 (2019).

  “Optimal Joint Estimation and Identification Theorem to Linear Guaussian System with Unknown Inputs”, S. Wang, C. Li, A. Lim, Signal Processing 161, 268-288 (2019).

  “Textual sentiment of Chinese microblog toward the stock market”, N. Wang, S. Ke, Y. Chen, T. Yan, A. Lim, International Journal of Information Technology and Decision Making (IJITDM) Vol 18 (2), 649-671 (2019).

  “A branch-and-price-and-cut algorithm for a pickup and delivery problem in retailing,” C Li, L Gong, Z Luo, A Lim, Omega, Vol. 89, Dec. 2019, Pages 71-91.

  “A branch-and-price algorithm for the two-dimensional vector packing problem”, L Wei, M Lai, A Lim, Q Hu, European Journal of Operational Research 281 (1), 25-35 (2020).

  “Optimal Solution of Vehicle Routing Problems with Fractional Objective Function”, R. Baldacci, A. Lim, E. Traversi, R. Wolfer Calvo, Transportation Science, 54(2): 434-452 (2020).

  “A New Branch-and-Price-and-Cut Algorithm for One-Dimensional Bin Packing Problems,” L. Wei, Z. Luo, R. Baldacci, A. Lim, Informs Journal on Computing, 32(2): 428-443 (2020).

  “Mitigating overtime risk in tactical surgical scheduling”, Y. Zhang, Y. Wang, J. Tang, A. Lim, Omega, Volume 93, June 2020, 102024.

  “Inertial proximal gradient methods with Bregman regularization for a class of nonconvex optimization problems”, Z Wu, C Li, M Li, A Lim, Journal of Global Optimization, 1-28 (2020).

  “An integrated route, temperature and humidity planning problem for the distribution of perishable products”, Yijing Liang, Fan Liu, Andrew Lim, Dezhi Zhang, Computers Industrial Engineering, Volume 147, 106623 (2020).

  “Optimal pricing decisions for an omni‐channel supply chain with retail service,” Yuqing Jiang, Liping Liu, Andrew Lim, International Transactions in Operational Research, Volume 27, Issue 6, Pages 2927-2948 (2020)

  “Efficient Approximation Algorithms for Adaptive Influence Maximization,” K. Huang, J. Tang, K. Han, X. Xiao, X. Chen, A. Sun, X. Tang, and A. Lim, The International Journal on Very Large Data Bases (VLDBJ), 29(6), 1385-1406 (2020).

  “An Exponential Factorization Machine with Percentage Error Minimization to Retail Sales Forecasting”, C. Li, B. Cheang, Z. Luo, A. Lim, ACM Transactions on Knowledge Discovery from Data, Volume 15(2), pp 1-32, (2021).

  “A Benders Decomposition Approach for the Multi-Vehicle Production Routing Problem with Order-up-to-level Policy,” Z. Zhang, Z. Luo, R. Baldacci, A. Lim, Transportation Science, Volume 55(1), pp160-178 (2021).

  “Multi-Trip Time-Dependent Vehicle Routing Problem with Time Windows,” B. Pan, Z. Zhang, A. Lim, European Journal of Operational Research, 291(1), 218-231 (2021).

  “An exact algorithm for the unidirectional quay crane scheduling problem with vessel stability,” D. Sun, L. Tang, R. Baldacci, A. Lim, European Journal of Operational Research 291(1), 271-283 (2021).

  “Accurate Tracking, Collision Detection, and Optimal Scheduling of Airport Ground Support Equipment”, S. Wang, Y. Che, H. Zhao, A. Lim, IEEE Internet of Things Journal, 8(1), 572-584 (2021).

  “A hybrid algorithm for time-dependent vehicle routing problem with time windows,” B. Pan, Z. Zhang, A. Lim, Computers and Operations Research, Volume 128, 105193 (2021).

  “Revisiting Modified Greedy Algorithm for Monotone Submodular Maximization with a Knapsack Constraint,” J. Tang, X. Tang, A. Lim, K. Han, C. Li, J. Yuan, Proc. ACM Meas. Anal. Comput. Syst., Vol. 5, No. 1, Article 8, March 2021.

  “A Branch-and-price-and-cut algorithm for the cable routing problem in solar power plants,” Z. Luo, H. Qin, TCE Cheng, Q. Wu, Informs Journal on Computing, accepted (2020).

  “Robust Data-Driven Vehicle Routing with Time Windows”, Y. Zhang, Z. Zhang, A. Lim, M. Sim, Operations Research, accepted in 2020.

  “ROPHS: Determine Real-Time Status of a Multi-Carriage Logistics Train at Airport,” S. Wang, C. Li, A. Lim, IEEE Transactions on Intelligent Transportation System, accepted (2021).

  “Heterogeneous Attentions for Solving Pickup and Delivery Problem via Deep Reinforcement Learning, IEEE Transactions on Intelligent Transportation System, accepted (2021).

  “A Model for Non-Stationary Time Series and Its Applications in Filtering and Anomaly Detection,” S. Wang, C. Li, A. Lim, IEEE Transactions on Instrumentation & Measurement, accepted (2021)

  “Machine scheduling with orientation selection and two-dimensional packing for additive manufacturing,” Kanxin Hu, Y. Che, Zhenzhen Zhang, Andrew Lim, Computers and Operations Research (2021)

  “A Heuristic Algorithm for Solving a Large-scale Real-world Territory Design Problem,” L. Zhou, L Zhen, R Baldacci, M Boschetti, Y. Dai, A Lim, Omega, accepted (2021).

  “Learning Improvement Heuristics for Solving Routing Problems", Wu, Yaoxin; Song, Wen; Cao, Zhiguang; Zhang, Jie; Lim, Andrew, IEEE Transactions on Neural Networks and Learning Systems, accepted (2021).
`;

  function articles() {
    let lines = all.replace('\r', '').split('\n');
    return lines.filter((line) => line.trim().length > 0)
      .map((line, i) =>
        <div className="text-sm text-left mb-3" key={i}>{line}</div>
    );
  }

  return (
    <div className="app-max-width">
      <div className="mt-10">
        <img className="w-full block" src="/images/research.png" alt="research"></img>
        <div className="text-gray-600 text-center pt-6 px-10 pb-10 max-w-screen-md mx-auto">
          {articles()}
        </div>
      </div>
    </div>
  );
}

export default Research;
