
import React from "react";

import HorizontalBar from './common/HorizontalBar';

function SampleClients() {

  return(
    <div className="mx-auto app-max-width">
      <div className="relative w-full text-center">
        <a id="projects">
          <img className="w-full" src="/images/bg-projects.png" alt="backgrond"></img>
        </a>
        <div className="absolute w-full top-32 md:top-60">
          <img className="block w-2/3 mx-auto" alt="projects" src="/images/text-projects.png"></img>
        </div>
      </div>

      {/* <HorizontalBar color="#a7a7a7">
        <div className="pt-1 text-gray-200 text-3xl">SAMPLE CLIENT PORTFOLIO</div>
      </HorizontalBar>

      <div>
        <img className="w-full" src="/images/sample-clients.png" alt="sample clients"></img>
      </div> */}
  </div>);
}

export default SampleClients;
