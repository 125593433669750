
import React from "react";

import HorizontalBar from './common/HorizontalBar';


function Founder(props) {
  return (
    <div className="w-full p-4 bg-gray-100">
      <div className="w-full sm:w-1/3 float-left mr-4 mb-2">
        <img className="w-full" src={props.src} alt=""></img>
      </div>
      <div className="text-justify text-sm">{props.children}</div>
    </div>
  );
}

function Team(props) {
  const teamMembers = [
    {
      photo: '/images/profile/steve.jpg',
      name: 'STEVE AU YEUNG',
      position: 'PRINCIPAL CONSULTANT',
      bio: 'Business Development Forwarding & Distribution',
    },
    {
      photo: '/images/profile/felix.jpg',
      name: 'FELIX YUE',
      position: 'PRINCIPAL CONSULTANT',
      bio: 'Business Development Air, Courier, Ocean, Distribution Network',
    },
    {
      photo: '/images/profile/kenneth.jpg',
      name: 'KENNETH WING',
      position: 'PRINCIPAL CONSULTANT',
      bio: 'Business Development Air, Ocean, Trucking Strategy Development & Negotiation',
    },
    {
      photo: '/images/profile/mohan.jpg',
      name: 'MOHAN DAS',
      position: 'STRATEGIC ADVISOR',
      bio: 'Business Development',
    },
    {
      photo: '/images/profile/sam.jpg',
      name: 'SAM SHEN',
      position: 'CHIEF SYSTEM ARCHITECT',
      bio: 'Project Management, Systems Development, Server Management & System/Service Hosting',
    },
    {
      photo: '/images/profile/max.jpg',
      name: 'MAX MOK',
      position: 'CHIEF MACHINE LEARNING ARCHITECT',
      bio: 'Project Management, System Development & Forecasting',
    },
    {
      photo: '/images/profile/GARY.jpg',
      name: 'GARY ZHONG',
      position: 'SYSTEM ANALYST',
      bio: 'System Analysis & Development, Project Management',
    },
    {
      photo: '/images/profile/OUYANG.jpg',
      name: 'OUYANG XIANGLONG',
      position: 'SENIOR SERVER SYSTEM DEVELOPER',
      bio: 'System Development, Project Management',
    },
    {
      photo: '/images/profile/JANE.jpg',
      name: 'JANE HUANG',
      position: 'SENIOR SERVER SYSTEM DEVELOPER',
      bio: 'System Development',
    },
    {
      photo: '/images/profile/XIAOYU.jpg',
      name: 'CAI XIAOYU',
      position: 'MOBILE APP DEVELOPER',
      bio: 'Mobile App Development',
    },
    {
      photo: '/images/profile/RONGYI.jpg',
      name: 'ZHENG RONGYI',
      position: 'SERVER SYSTEM DEVELOPER',
      bio: 'System Development',
    },
    {
      photo: '/images/profile/YANICE.jpg',
      name: 'YANICE LIU',
      position: 'GRAPHIC DESIGNER',
      bio: 'Graphic & Web Page Design',
    },
    {
      photo: '/images/profile/hubert.jpg',
      name: 'HUBERT WANG',
      position: 'ADVISOR',
      bio: 'Business Development, Retail Systems',
    },
    {
      photo: '/images/profile/foomoo.jpg',
      name: 'FOO MOO KWANG',
      position: 'ADVISOR',
      bio: 'Business Development Agriculture, IP, Thailand Specialist',
    },
    {
      photo: '/images/profile/belinda.jpg',
      name: 'BELINDA CHEANG',
      position: 'SNR CONSULTANT',
      bio: 'Education Systems',
    },
    {
      photo: '/images/profile/yvelin.jpg',
      name: 'YVELIN LIM',
      position: 'CONSULTANT',
      bio: 'Food & Beverage<br>Analytics',
    },
  ];
  return (
    <div className="flex flex-wrap justify-center">
      {teamMembers.map((m, i) => <Member key={i} {...m} />)}
    </div>
  );
}

function Member(props) {
  return (
    <div className="w-64 mx-2 mb-4">
      <img className="block mx-auto rounded-full w-40 border-4" src={props.photo} alt=""></img>
      <div className="my-2">{props.name}</div>
      <div className="my-2">{props.position}</div>
      <div className="px-2 pb-5 mx-auto max-w-xs text-gray-600" dangerouslySetInnerHTML={{ __html: props.bio}} ></div>
    </div>
  );
}

function AboutUs() {

  const wrapperClassName = "text-gray-600 text-center pt-6 px-10 pb-10 max-w-screen-md mx-auto";
  const pClassName = "mb-2";

  return (
    <div className="app-max-width">
      <div className="mt-0">
        <a id="about-us">
          <img className="w-full block bg-gray-100" src="/images/about-us.png" alt="about us"></img>
        </a>

        <HorizontalBar color="#a7a7a7">
          <div className="pt-1 text-gray-200 text-3xl">ABOUT US</div>
        </HorizontalBar>

        <div className={wrapperClassName}>
          <p>
          Red Jasper is a boutique technology consultancy and an incubator of deep-tech products and services. We harness our advanced research capabilities to create innovative products, applications and solutions that not only address current and emerging needs of our clients, but also foster strategic alliances and enduring partnerships with companies of complementary strengths to generate extraordinary value.
          </p>
        </div>

        <HorizontalBar color="#a7a7a7">
          <div className="pt-1 text-gray-200 text-3xl">FOUNDERS</div>
        </HorizontalBar>

        <div className={wrapperClassName}>
          <Founder src="/images/profile/andrew.jpg">
            <p className={pClassName}>
            Prof Andrew Lim is a deep tech specialist and researcher. Andrew’s body of work draws from knowledge in computing, engineering, commerce and management to drive research initiatives, as well as design and develop innovative and effective industrial systems and processes leading to digital transformation.
            </p>
            <p className={pClassName}>Andrew has held tenured full professorships and headships in top Universities in Asia. In 2013, Andrew was recruited by Nanjing University under the prestigious Thousand Talents program that was launched by the Chinese government. In 2016, Andrew became one of only six Singaporeans to-date to clinch the prestigious National Research Foundation's Returning Singaporean Scientists Award. Andrew is also ranked within 0.6% of the world’s top scientists (career) based on the research done by Chair Professor John Ioannidis from Stanford University (reference link: https://journals.plos.org/plosbiology/article?id=10.1371/journal.pbio.3000918).</p>
            <p className={pClassName}>Throughout his career, Andrew has been actively involved in industry-linked R&D initiatives. While some of them involve big data analytics, digital twin, and digital transformation, others are concerned with helping organizations compete effectively by tackling strategic, tactical and operational problems in the domains of healthcare, logistics and transportation. Thus, much of Andrew’s work not only help his clients save money and operate more efficiently, his research has been published in key journals in Operations Research and Management Science, and in top international conferences in Computer Science. </p>
          </Founder>

          <Founder src="/images/profile/brenda.jpg">
            <p className={pClassName}>
            Brenda Cheang obtained her PhD in Education (IT Studies) from the University of Hong Kong, MPhil in Management Sciences from the City University of Hong Kong, and a Bachelor of Science in Information Technology (First Class Honors) from University College Dublin.
            </p>
            <p className={pClassName}>
            Brenda is a seasoned technopreneur. She was instrumental in securing start-up funding both in Singapore and Hong Kong. At present, apart from serving as the Executive Director of Red Jasper, Brenda also leads efforts to expand the company’s body of work through deep consulting, strategic partnerships, incubation and spin-off ventures. She is also the managing director for Ascended and WiFreight, two of Red Jasper’s spin-offs.
            </p>
            <p className={pClassName}>
            Brenda has significant experience in various elements of the tech industry; from starting up, to product/service design, marketing, project management and business development. Between roles in the corporate world, she pursued her masters and doctoral studies, and also worked in various capacities in academia, including being an adjunct faculty at the National University of Singapore (NUS), Deputy Director of the Master of Science in Analytics Program at Nanyang Technological University (NTU), and assisting the NUS Office of the Provost to establish their Institute of Applications for Learning Science and Educational Technology (ALSET).
            </p>
          </Founder>
        </div>

        <HorizontalBar color="#a7a7a7">
          <div className="pt-1 text-gray-200 text-3xl">TEAM MEMBERS</div>
        </HorizontalBar>

        <Team />

      </div>
    </div>
  );
}

export default AboutUs;
